




















import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { inviteCredits, sendToApp, toReadableNumber } from '@/helpers'
import { useI18n } from 'vue-composable'
import store from '@/store'
import { IS_LOGGING } from '@/data/constants'

interface State {
  [key: string]: any;
}

const {
  state: {
    auth
  },
  getters,
  commit
} = store

export default defineComponent({
  setup () {
    const { $t } = useI18n()

    const SHARE_LINK = `${process.env.VUE_APP_SENTBE_DOMAIN}/onelink?iframe=Y&invite_code=${auth.person.invite_code}`

    const state = reactive({
      $t,
      messageParams: computed(() => {
        const params = {
          invite_code: auth.person.invite_code,
          amount: toReadableNumber(inviteCredits(getters['country/$currentSourceCountryCode'])),
          currency: getters['country/$currentSourceCurrencyIso'],
          app_store_url: SHARE_LINK
        }
        return params
      }),
      params: ({ value = '', link = '', button = '' }) => computed(() => {
        return {
          type: value,
          message: $t('Benefit.inviting.friends.deeplink', state.messageParams).value,
          link,
          button
        }
      }),
      channels: [
        { src: require('@/assets/images/Messenger.svg'), value: 'messenger', alt: 'sms', link: SHARE_LINK },
        { src: require('@/assets/images/KakaoTalk.svg'), value: 'kakaoTalk', alt: 'kakao', link: SHARE_LINK, button: $t('Global.button.Share.none').value },
        { src: require('@/assets/images/Facebook.svg'), value: 'facebook', alt: 'facebook', link: SHARE_LINK },
        { src: require('@/assets/images/WhatsApp.svg'), value: 'whatsApp', alt: 'whatsApp', link: SHARE_LINK },
        { src: require('@/assets/images/Etc.svg'), value: 'etc', alt: 'etc', link: SHARE_LINK }
      ]
    }) as State

    const clickShareMyInviteCode = (channel: any) => {
      sendToApp('shareLink', state.params(channel).value)
      sendToApp('click_share_MyInviteCode', { invite_channel: channel.alt }, IS_LOGGING)
    }

    const clickMyInviteCode = () => {
      sendToApp('start_myInviteCode', {}, IS_LOGGING)
      commit('modal/$OPEN_MODAL', 'invite-code-modal')
    }

    return {
      ...toRefs(state),
      sendToApp,
      clickMyInviteCode,
      clickShareMyInviteCode
    }
  }
})
